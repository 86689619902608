import logo from '../src/logo.png';
import logoBlue from '../src/logo-blue.png';
import code from '../src/code.png';
import macbook from '../src/macbook.png';
import hand from '../src/desk.png';
import desk from '../src/bionic-solutions.jpeg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div class="App">
      <header class="App-header d-none d-xl-block">
        <img src={logo} id="logo"alt="logo" />
        <div id="company-name">
          <p id="header">Bionic Solutions</p>
          <p id="slogan">World class tech, at everyone's fingertips</p>
        </div>
      </header>
      <header class="App-header d-xl-none">
        <img src={logoBlue} id="logoSM"alt="logo" />
        <div id="company-name-sm">
          <p id="headerSM">Bionic Solutions</p>
          <p id="sloganSM">World class tech, at everyone's fingertips</p>
        </div>
      </header>
      <div id="mission" class="row info-block">
        <div class="col-11 box">
          <div class="row">
            <div class="col-lg-6">
              <div class="text">
                <p id="title-blue">What do we do?</p>
                <p class="information">Assistive technology should be available to everyone who needs it and not just those that can afford it. That is why we have dedicated ourselves to providing cutting edge software and chips to power the world's most advanced bionic hands, at a 1000th of the cost of the industry standard. Our plug-and-play chips come with class-leading features, including variable speed control, multi finger movement, dozens of modes, relaxed resting position, grip locking, optional OLED and vibration feedback integration, plus many more. </p>
              </div>
            </div>
            <div class="col-lg-6 image-box">
              <span class="image-centerer"></span>
              <img class="image" src={desk} alt=""></img>
            </div>
          </div>
        </div>
      </div>
      <div id="charity" class="row info-block">
        <div class="col-lg-6 image-box">
          <span class="image-centerer"></span>
          <img class="image" src={hand} alt=""></img>
        </div>
        <div class="col-lg-6">
          <div class="text">
            <p id="title-grey">Free hands for everyone!</p>
            <p class="information">We have partnered with charity Free 3D Hands to develop the worlds first advanced bionic arm that is donated to users completely free. The hands are entirely 3D printed, which allows us to build them in any size from an 8 year old child's hand to that of a fully grown man, and print them in any combination of colours.</p>
            <p class="information">On top of donating the hands we produce for free, extra effort has been made to allow as many people as possible to make their own. It has been designed with affordable, readily available components with minimal assembly, and all code, designs, and documentation will be released free for anyone to download. While functionally equivalent models currently retail between $20,000 and $60,000, soon anyone with access to a 3D printer will be able to build their own for less than $50</p>
          </div>
        </div>
      </div>
      <div id="console" class="row info-block">
        <div class="col-11 box">
          <div class="row">
            <div class="col-lg-6">
              <div class="text">
                <p id="title-blue">Make it your own</p>
                <p class="information">Monitor, diagnose, and configure your bionic arm with our state-of-the-art web console. Engineers can view the internal states of the motors and sensor readings in real time to assist with designing and building their hand, or users can set the grip modes they use most and choose the order they cycle through them. Almost all components of the hand can be customised through the console to allow maximum flexibility in your designs, from the number of motors used, to the sensitivity of the sensor, to the functionality of the buttons, and much more.</p>
              </div>
            </div>
            <div class="col-lg-6 image-box">
              <span class="image-centerer"></span>
              <img class="image" src={macbook} alt=""></img>
            </div>
          </div>
        </div>
      </div>
      <div id="education" class="row info-block">
        <div class="col-lg-6 image-box">
          <span class="image-centerer"></span>
          <img class="image" src={code} alt=""></img>
        </div>
        <div class="col-lg-6">
          <div class="text">
            <p id="title-grey">Empowering the future</p>
            <p class="information">The world would be a better place if more people developed technology to help others, but for many, robotics and engineering is too intimidating. With this in mind, Bionic Solutions has designed a simplified bionic arm to introduce students to software and engineering principles. The education package teaches students to design and print 3D parts, breadboard and solder electronic circuits, understand human-readable code and upload it to a chip, and assemble all components together to build their own bionic arm. The kit has been used by year 9 students with success, and aims to reduce the barrier to engineering.</p>
          </div>
        </div>
      </div>
      <br></br><br></br><br></br>
      <div id="footer"> For more information, email info@bionic.solutions</div>
    </div>
  );
}

export default App;
